<script>
import Swal from "sweetalert2";
import Layout from "@/layouts/main.vue";
import appConfig from "@/app.config";
import "@vueform/multiselect/themes/default.css";
import PageHeader from "@/components/page-header";
import animationData from "@/components/widgets/msoeawqm.json";
import Lottie from "@/components/widgets/lottie.vue";
import _ from "lodash";

export default {
  page: {
    title: "List",
    checkAll: false,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      modalTitle: "",
      title: "List",
      items: [
        {
          text: "currency",
          href: "/",
        },
        {
          text: "List",
          active: true,
        },
      ],
      searchQuery: null,
      defaultOptions: {
        animationData: animationData,
      },
      sliderCustomzie: [1000, 3000],
      CurrencyData: [],
      pages: [],
      page: 1,
      perPage: 10,
      value: ["Watches", "Headset"],
      CurrencyDetailData: {},
      // Currency formData
      formData: {
        code: "",
        name: "",
        symbol: ""
        
      },
      table_options: {
        current_page: 1,
        page_size: 10,
        search_text: "",
        sort: { column: "", direction: "" },
      },
      meta: {},
      selectedcurrencyIds:[]
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getCurrency();
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {

      // child checkboxes
    handleChildCheckBoxChange(payload) {
      if (this.selectedcurrencyIds.includes(payload.data.id)) {
        let index = this.selectedcurrencyIds.indexOf(payload.data.id);
        this.selectedcurrencyIds.splice(index, 1);
      } else {
        this.selectedcurrencyIds.push(payload.data.id);
      }
      var total = 0;
      var checkchild = document.querySelectorAll(
        '.form-check-all input[type="checkbox"]'
      );
      checkchild.forEach(function (checkbox) {
        checkbox.onclick = function () {
          total = 0;
          checkchild.forEach(function (box) {
            if (box.checked == true) {
              total++;
            }
          });
          if (checkbox.checked == true) {
            checkbox.closest("tr").classList.add("table-active");
            document.getElementById("selection-element").style.display =
              "block";
            document.getElementById("select-content").innerHTML = total;
          } else {
            if (total <= 0) {
              document.getElementById("selection-element").style.display =
                "none";
            }
            checkbox.closest("tr").classList.remove("table-active");
            document.getElementById("select-content").innerHTML = total;
          }
        };
      });
    },

      // All checkbox
    handleCheckAllProductChange() {
      let checkboxes = document.querySelectorAll(
        '.form-check-all input[type="checkbox"]'
      );
      if (this.checkAll) {
        this.selectedcurrencyIds = [];
        this.CurrencyData.forEach((data) =>
          this.selectedcurrencyIds.push(data.id)
        );
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = true;
          document.getElementById("selection-element").style.display = "block";
          document.getElementById("select-content").innerHTML =
            checkboxes.length;
          checkbox.closest("tr").classList.add("table-active");
        });
      } else {
        this.selectedcurrencyIds = [];
        checkboxes.forEach(function (checkbox) {
          checkbox.checked = false;
          document.getElementById("selection-element").style.display = "none";
          checkbox.closest("tr").classList.remove("table-active");
        });
      }
    },


    //open edit Currency
    openEditCurrency(data) {
      this.modalTitle = "Edit Currency";
      this.formData = {
        ...data,
      };
    },
    //add and edit Currency data
    submitCurrencyData() {
      if (this.modalTitle === "Add Currency") {
        this.$store
          .dispatch("currency/addCurrency", this.formData)
          .then(() => {
            this.getCurrency();
            Swal.fire("Success!", "Currency added successfully.", "success");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$store
          .dispatch("currency/editCurrency", this.formData)
          .then(() => {
            const newCurrencyData = this.CurrencyData.map((obj) => {
              if (obj.id === this.formData.id) {
                return { ...this.formData };
              }
              return obj;
            });
            this.CurrencyData = newCurrencyData;
            Swal.fire(
              "Success!",
              "Currency data updated successfully.",
              "success"
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
      document.getElementById("closemodal").click();
      document.getElementById("addform").reset();
    },
    //get all currency
    getCurrency() {
      this.$store
        .dispatch("currency/getCurrencies", this.table_options)
        .then((res) => {
          console.log(res)
          this.CurrencyData = res.data;
          this.meta.total = res.total;
          this.meta.from = res.from;
          this.meta.last_page = res.last_page;
        })
        .catch((err) => console.log(err));
    },
    //delete Currency data
    deleteCurrencyData(event) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(`currency/deleteCurrency`, event.id)
            .then(() => {
            this.getCurrency()
              Swal.fire(
                "Deleted!",
                "Currency data deleted successfully.",
                "success"
              );
            })
            .catch((er) => {
              console.log(er);
            });
        }
      });
    },

     deleteMultiple() {
      if (typeof this.selectedcurrencyIds !== "undefined" && this.selectedcurrencyIds.length > 0) {
       Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete this?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonColor: "#34c38f",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(`currency/deleteMultipleCurrencies`,this.selectedcurrencyIds)
            .then(() => {
              this.getCurrency()
              document.getElementById("checkAll").checked = false;
              var itemss = document.getElementsByName("chk_child");
              itemss.forEach(function (ele) {
                if (ele.checked == true) {
                  ele.checked = false;
                  ele.closest("tr").classList.remove("table-active");
                  document.getElementById("selection-element").style.display =
                    "none";
                }
              });
              Swal.fire(
                "Deleted!",
                "Vendor deleted successfully.",
                "success"
              );
            })
            .catch((er) => {
              console.log(er);
            });
        }
      });
      } else {
        Swal.fire({
          title: "Please select at least one checkbox",
          confirmButtonClass: "btn btn-info",
          buttonsStyling: false,
          showCloseButton: true,
        });
      }
    },
   

    debounceInput: _.debounce(function (e) {
      this.table_options.search_text = e.target.value;
      this.$store
        .dispatch("currency/getCurrencys", this.table_options)
        .then((response) => {
          this.CurrencyData = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    }, 500),

    handlePageChange(e) {
      this.table_options.current_page = e;
      this.$store
        .dispatch("currency/getCurrencys", this.table_options)
        .then((response) => {
          this.CurrencyData = response.data;
          this.meta.total = response.total;
          this.meta.from = response.from;
          this.meta.last_page = response.last_page;
        });
    },
  },
  mounted() {
    this.getCurrency();
   
  },

  components: {
    Layout,
    PageHeader,
    lottie: Lottie,
  },
};
</script>

<template>
  <Layout>
    <!-- end table responsive -->
    <PageHeader :title="title" :items="items" />
    <!-- ADD Currency MODAL -->
    <div
      class="modal fade"
      id="showModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <form action="#" id="addform" @submit.prevent="submitCurrencyData">
            <div class="modal-body">
              <div class="mb-3" id="modal-id">
                <label for="name" class="form-label">Currency Name</label>
                <input
                  type="name"
                  v-model="formData.name"
                  id="name"
                  class="form-control"
                  placeholder="Enter Name"
                  required
                />
              </div>

              <div class="mb-3">
                <label for="code" class="form-label">Currency Code</label>
                <input
                  type="text"
                  v-model="formData.code"
                  id="code"
                  class="form-control"
                  placeholder="Enter code"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="symbol" class="form-label">Currency Symbol</label>
                <input
                  type="tel"
                  v-model="formData.symbol"
                  id="symbol"
                  class="form-control"
                  placeholder="Enter symbol"
                  required
                />
              </div>
             
            </div>
            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  type="button"
                  class="btn btn-light"
                  data-bs-dismiss="modal"
                  id="closemodal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-success" id="add-btn">
                  {{ modalTitle }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END ADD Currency MODAL -->

    <!-- Currency DETAILS -->
    <div
      class="modal fade"
      id="showCurrencyDetailModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-light p-3">
            <h5 class="modal-title" id="exampleModalLabel">Currency Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="close-modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <th class="ps-0" scope="row">Currency Name :</th>
                    <td class="text-muted">{{ CurrencyDetailData.name }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Currency code :</th>
                    <td class="text-muted">{{ CurrencyDetailData.code }}</td>
                  </tr>
                  <tr>
                    <th class="ps-0" scope="row">Currency symbol :</th>
                    <td class="text-muted">{{ CurrencyDetailData.symbol }}</td>
                  </tr>
                 
                  
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-2 justify-content-end">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
                id="closemodal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END Currency DETAILS -->
    <div class="row">
      <div class="col-md-12">
        <div>
          <div class="card">
            <div class="card-header border-0">
              <div class="row g-4">
                <div class="col-sm-auto">
                  <div>
                    <button
                      type="button"
                      @click="modalTitle = 'Add Currency'"
                      class="btn btn-success add-btn"
                      data-bs-toggle="modal"
                      id="create-btn"
                      data-bs-target="#showModal"
                    >
                      <i class="ri-add-line align-bottom me-1"></i> Add Currency
                    </button>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="d-flex justify-content-sm-end">
                    <div class="search-box ms-2">
                      <input
                        type="text"
                        @input="debounceInput"
                        class="form-control"
                        placeholder="Search Products..."
                      />
                      <i class="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <ul
                    class="nav nav-tabs-custom card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active fw-semibold"
                        data-bs-toggle="tab"
                        href="#productnav-all"
                        role="tab"
                      >
                        All

                        <span
                          class="badge badge-soft-danger align-middle rounded-pill ms-1"
                          >{{ meta.total }}</span
                        >
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">
                    <div class="my-n1 d-flex align-items-center text-muted">
                      Select
                      <div
                        id="select-content"
                        class="text-body fw-semibold px-1"
                      ></div>
                      Result
                      <button
                        type="button"
                        class="btn btn-link link-danger p-0 ms-3 shadow-none"
                        data-bs-toggle="modal"
                        data-bs-target="#removeItemModal"
                        @click="deleteMultiple"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
            <div class="card-body">
              <div class="tab-content text-muted">
                <div
                  class="tab-pane active"
                  id="productnav-all"
                  role="tabpanel"
                >
                  <div class="table-responsive">
                    <table class="table align-middle table-nowrap mb-0">
                      <thead class="table-light">
                        <tr>
                          <th scope="col" style="width: 50px">
                            <div class="form-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                 id="checkAll"
                                style="cursor:pointer"
                                v-model="checkAll"
                                 @change="handleCheckAllProductChange"
                              />
                            </div>
                          </th>
                          <th>Name</th>
                          <th>Code</th>
                          <th>Symbol</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody class="list form-check-all">
                        <tr
                          class="gridjs-tr"
                          v-for="(data, index) of CurrencyData"
                          :key="index"
                        >
                          <td
                            data-column-id="productListAllCheckbox"
                            class="gridjs-td"
                          >
                            <input
                              type="checkbox"
                              name="chk_child"
                             @change="
                                handleChildCheckBoxChange({
                                  data: data,
                                  index: index,
                                })
                              "
                              style="cursor: pointer"
                              class="form-check-input form_chk_child"
                            />
                          </td>
                          <td class="id" hidden>
                            <a href="">{{ data.id }}</a>
                          </td>

                          <td data-column-id="name" class="gridjs-td">
                            {{ data.name }}
                          </td>
                          <td data-column-id="code" class="gridjs-td">
                            {{ data.code }}
                          </td>
                          <td data-column-id="symbol" class="gridjs-td">
                            {{ data.symbol }}
                          </td>
                        
                          <td data-column-id="action" class="gridjs-td">
                            <span>
                              <div class="dropdown">
                                <button
                                  class="btn btn-soft-secondary btn-sm dropdown"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i class="ri-more-fill"></i>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <button
                                      data-bs-toggle="modal"
                                      id="details-btn"
                                      data-bs-target="#showCurrencyDetailModal"
                                      @click="CurrencyDetailData = { ...data }"
                                      class="dropdown-item"
                                    >
                                      <i
                                        class="ri-eye-fill align-bottom me-2 text-muted"
                                      ></i>
                                      View
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      @click="openEditCurrency(data)"
                                      class="dropdown-item"
                                      data-bs-toggle="modal"
                                      id="edit-btn"
                                      data-bs-target="#showModal"
                                    >
                                      <i
                                        class="ri-pencil-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Edit
                                    </button>
                                  </li>
                                  <li class="dropdown-divider"></li>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      @click="deleteCurrencyData(data)"
                                    >
                                      <i
                                        class="ri-delete-bin-fill align-bottom me-2 text-muted"
                                      ></i>
                                      Delete</a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div 
                  @click="handlePageChange(table_options.current_page)"
                  class="d-flex mt-4 mx-3 align-items-center justify-content-end"
                >
                  <b-pagination
                    v-model="table_options.current_page"
                    :total-rows="meta.total"
                    :per-page="table_options.page_size"
                    prev-text="Prev"
                    next-text="Next"
                    prev-class="prev"
                    next-class="next"
                    class=" pagination-wrap hstack gap-2"
                  >
                    <template #prev-text>
                      <div class="page-item shadow-sm pagination-prev disabled">
                        Previous
                      </div>
                    </template>
                    <template #next-text>
                      <div class="page-item shadow-sm  pagination-next">Next</div>
                    </template>
                  </b-pagination>
                </div>
                  </div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="productnav-published" role="tabpanel">
                  <div
                    id="table-product-list-published"
                    class="table-card gridjs-border-none"
                  ></div>
                </div>
                <!-- end tab pane -->

                <div class="tab-pane" id="productnav-draft" role="tabpanel">
                  <div class="py-4 text-center">
                    <div>
                      <lottie
                        class="avatar-xl"
                        colors="primary:#121331,secondary:#08a88a"
                        :options="defaultOptions"
                        :height="75"
                        :width="75"
                      />
                    </div>

                    <div class="mt-4">
                      <h5>Sorry! No Result Found</h5>
                    </div>
                  </div>
                </div>
                <!-- end tab pane -->
              </div>
              <!-- end tab content -->
            </div>
            <!-- end card body -->
          </div>
          <!-- end card -->
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
